import { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { motion } from "framer-motion";
import Navigation from "./components/body/Navigation";
import Navbar from "./components/body/Navbar";
import Body from "./components/body/Body";
import Footer from "./components/body/Footer";
import "./components/styles/styles.css";

function App() {
  const [isVisible, setIsVisible] = useState(true);
  const containerRef = useRef(null);

  const handleIntersection = (entries) => {
    const entry = entries[0];
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: "-56px",
      threshold: 0,
    });

    const { current: currentRef } = containerRef;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [containerRef]);

  return (
    <Router>
      <div className="container-fluid head">
        <motion.div
          className="nav-bar"
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: isVisible ? -100 : 0, opacity: isVisible ? 0 : 1 }}
          transition={{ duration: 0.3 }}
          style={{ position: isVisible ? "absolute" : "fixed" }}
        >
          <Navbar />
        </motion.div>

        <motion.div className="container" ref={containerRef}>
          <div className="row">
            <motion.div
              initial={{ y: 150, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="col-12 col-md-5"
            >
              <div className="top-box">
                <div className="title">JUWU_ART_</div>
                <div className="subtitle">ILUSTRADORA DIGITAL</div>
              </div>
              <motion.img
                src="/img/logo.webp"
                alt="Logo"
                className="logo"
                animate={{ rotate: [15, -15, 15] }}
                transition={{
                  duration: 1.7,
                  repeat: Infinity,
                  repeatType: "mirror",
                  ease: "easeInOut",
                }}
              />
            </motion.div>
            <Navigation />
          </div>
        </motion.div>
      </div>
      <Body />
      <Footer />
    </Router>
  );
}

export default App;
