import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.closest("[data-nav-link]")) {
        setIsClicked(true);
      }
    };
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (isClicked) {
      const path =
        location.pathname === "/" ? "home" : location.pathname.substring(1);
      setTimeout(() => {
        const element = document.getElementsByClassName(path)[0];
        if (element) {
          smoothScrollTo(element, 300);
        }
        setIsClicked(false);
      }, 100);
    }
  }, [location, isClicked]);

  const smoothScrollTo = (element, duration) => {
    const targetPosition = element.getBoundingClientRect().top + window.scrollY - 45;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  return null;
}

export default ScrollToTop;
