import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("home");

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    setActivePage(currentPath === "" ? "home" : currentPath);
  }, [location]);

  const navLinks = [
    { path: "/", label: "Home" },
    { path: "/about", label: "Sobre" },
    { path: "/masterpiece", label: "Minha Obra" },
    { path: "/ebook", label: "E-book", url: "https://discord.gg/wNZrenRjYH" },
    {
      path: "/discord",
      label: "Discord",
      url: "https://discord.gg/wNZrenRjYH",
    },
    {
      path: "/onomatopeias",
      label: "Onomatopeias",
      url: "https://onomatopeias.com",
    },
  ];

  const handleLinkClick = (link) => {
    if (link.url) {
      window.open(link.url, "_blank");
    }
  };

  return (
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav">
            {navLinks.map((link) => (
              <li key={link.path} className={`nav-item`}>
                <Link
                  className={`nav-link ${
                    activePage ===
                    (link.path === "/" ? "home" : link.path.substring(1))
                      ? "active"
                      : ""
                  }`}
                  to={link.path}
                  data-nav-link
                  onClick={(e) => {
                    if (link.url) {
                      e.preventDefault();
                      handleLinkClick(link);
                    }
                  }}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
