import useCustomInView from "../tools/useCustomInView";
import { animated } from "@react-spring/web";

function Obs() {
  const [left1, styles1] = useCustomInView("left-right", 0);
  const [right1, styles2] = useCustomInView("right-left", 200);
  const [left2, styles3] = useCustomInView("left-right", 400);
  const [icon, styles4] = useCustomInView("bottom-top", 600);
  const [right2, styles5] = useCustomInView("right-left", 600);

  return (
    <div className="container-fluid obs-container">
      <div className="container">
        <div className="row">
          <animated.div
            className="col-12 col-lg-8 obs"
            ref={left1}
            style={styles1}
          >
            <div className="title">Observações</div>
            <ul>
              <li>Pagar 50% antes, e 50% depois da aprovação do esboço</li>
              <li>O valor pode aumentar dependendo da dificuldade</li>
              <li>Mande referências ou descrição para me ajudar</li>
              <li>Arte entregue via gmail</li>
              <li>Pagamento via pix</li>
              <li>Prazo a ser definido conforme o projeto</li>
              <li>Personagens adicionais 50% do valor</li>
            </ul>
          </animated.div>
          <animated.div
            className="col-12 col-lg-4 what-i-do"
            ref={right1}
            style={styles2}
          >
            <div>
              <div className="title">Tipos de Ilustrações que Faço:</div>
              <ul>
                <li>Humanos</li>
                <li>Super-heróis e vilões</li>
                <li>Furry</li>
                <li>Chibi</li>
                <li>Cartoon</li>
                <li>Ícones</li>
                <li>Fanart</li>
                <li>Gore moderado</li>
                <li>Ecchi</li>
                <li>Capas para livros e quadrinhos</li>
                <li>ETC</li>
              </ul>
            </div>
          </animated.div>
          <div className="w-100">
            <animated.div
              className="col-12 col-lg-8 contact-box"
              ref={left2}
              style={styles3}
            >
              <div className="ajust">
                <animated.div ref={icon} style={styles4}>
                  <img src="/img/contact_me.png" alt="" />
                </animated.div>
                <div className="button">Contate-me!</div>
              </div>
            </animated.div>
            <animated.div
              className="col-12 col-lg-4 what-i-dont"
              ref={right2}
              style={styles5}
            >
              <div>
                <div className="title">Tipo de Ilustrações que não faço:</div>
                <ul>
                  <li>Gore extremo</li>
                  <li>Cenários altamentes complexos</li>
                  <li>Realismo (não dominei 100%)</li>
                  <li>Ilustrações de robôs</li>
                  <li>Perspectivas complexas</li>
                </ul>
                <div className="sub-title">
                  Coisas que me deixem desconfortáveis...
                </div>
              </div>
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Obs;
