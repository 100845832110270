import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faWhatsapp,
  faLinkedin,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  const handleLinkClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="container-fluid footer">
      <div className="container">
        <div className="col-12 col-md-6"><Link to={"/"} data-nav-link>JUWU_ART_</Link></div>
        <div className="col-12 col-md-6">
          <div>
            <div className="title">FALE CONOSCO</div>
            <Link
              onClick={() =>
                handleLinkClick(
                  "https://api.whatsapp.com/send?phone=5592994801570"
                )
              }
            >
              <FontAwesomeIcon icon={faWhatsapp} /> +55 92 994801570
            </Link>
            <Link
              onClick={() =>
                handleLinkClick("mailto:julianafernandes1703@gmail.com")
              }
            >
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              julianafernandes1703@gmail.com
            </Link>
          </div>
        </div>
        <div className="col-12">
          <div className="social-media">
            <Link
              onClick={() =>
                handleLinkClick(
                  "https://www.linkedin.com/in/juliana-nascimento-fernandes-177772231/"
                )
              }
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
            <Link onClick={() => handleLinkClick("https://www.instagram.com/juwu_art_/")}>
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link onClick={() => handleLinkClick("https://www.facebook.com/juliana.nart.14")}>
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
