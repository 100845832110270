import { animated } from "@react-spring/web";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

import useCustomInView from "../tools/useCustomInView";

import arte_livre from "../img/slider/arte_livre.webp";
import personagens from "../img/slider/personagens.webp";
import arte_simples from "../img/slider/arte_simples.webp";
import logo from "../img/slider/logotipo_logomarca.webp";
import arte_perfil from "../img/slider/arte_para_perfil.webp";

function Slider() {
  const settings = {
    loop: true,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    slidesPerView: 4,
    spaceBetween: 20,
    breakpoints: {
      1200: {
        slidesPerView: 5,
      },
      998: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      0: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  };

  const images = [
    { src: arte_livre, alt: "Arte Livre", title: "Arte Livre" },
    {
      src: personagens,
      alt: "Personagens",
      title: "Personagens",
    },
    {
      src: arte_simples,
      alt: "Arte Simples",
      title: "Arte Simples",
    },
    {
      src: logo,
      alt: "Logotipo/Logomarca",
      title: "Logo",
    },
    {
      src: arte_perfil,
      alt: "Arte para Perfil",
      title: "Arte para Perfil",
    },
  ];

  const items = images.map((image, index) => (
    <SwiperSlide key={index}>
      <img src={image.src} alt={image.alt} />
      <div className="title">{image.title}</div>
    </SwiperSlide>
  ));

  const [titleRef, titleStyles] = useCustomInView("bottom-top", 100);

  return (
    <div className="container-fluid slider-container">
      <div className="container">
        <div className="row">
          <animated.div
            ref={titleRef}
            style={titleStyles}
            className="col-12 title-slide"
          >
            <div className="box-top">
              <div className="purple-star icon">
                <img src="/img/purple_star.svg" alt="purple star" />
              </div>
              <div className="title">Faço comissões</div>
              <div className="subtitle">
                Transforme suas ideias em arte única com preços acessíveis. Faça
                sua encomenda agora!
              </div>
              <div className="yellow-star icon">
                <img src="/img/yellow_star.svg" alt="yellow star" />
              </div>
            </div>
          </animated.div>
          <animated.div className="col-12 slide">
            <Swiper modules={[Autoplay]} {...settings}>
              {items}
            </Swiper>
          </animated.div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
