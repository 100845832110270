import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../tools/ScrollToTop";
import Home from "../../pages/Home";
import About from "../../pages/About";
import Masterpiece from "../../pages/Masterpiece";

function Body() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
                <Home />
            </React.Fragment>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <React.Fragment>
                <About />
            </React.Fragment>
          }
        />
        <Route
          exact
          path="/masterpiece"
          element={
            <React.Fragment>
                <Masterpiece />
            </React.Fragment>
          }
        />
      </Routes>
    </div>
  );
}

export default Body;