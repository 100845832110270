import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { useInView } from "react-intersection-observer";

function Testimonials() {
  const handleLinkClick = (link) => {
    window.open(link, "_blank");
  };

  const [isInView, setIsInView] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    // Verifica se ainda não está em vista e atualiza o estado
    if (!isInView && inView) {
      setIsInView(true);
    }
  }, [inView, isInView]);

  const settings = {
    loop: true,

    slidesPerView: 3,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
  };

  const Testimonials = [
    {
      url: "https://www.instagram.com/jedson_jesney_/",
      title: "@jedson_jesney_",
      text: "Trabalhei com a Ju pintando algumas páginas de HQ, e foi uma experiência incrível. Ela é super dedicada à arte e me ajudou muito. Se alguém precisar de ajuda com colorização digital, estou à disposição!",
    },
    {
      url: "https://www.instagram.com/mzxnx77/",
      title: "@mzxnx77",
      text: "muito bom, preço acessível, desenha muito bem! criatividade além do universo para poder criar um desenho referente a mim, diante de um jogo que foi o meu caso, gostei bastante é indico e tenho minha confirmação que vale super a pena!",
    },
    {
      url: "https://www.instagram.com/taisambientais/",
      title: "@taisambientais",
      text: "Ola Juliana,passando para parabeniza-la e agradecer pelos trabalhos desenvolvidos com meus personagens taisambientais.Tratamos tudo on line e voce cumpriu prazos e manteve qualidade e bom gosto no desenvolvimento das artes.O banner sobre Mudanças Climáticas criado por vc  esta sendo um sucesso em minha palestras. Teremos muito trabalho em breve. Sucesso para nós.",
    },
    {
      url: "https://www.instagram.com/vozgravada/",
      title: "@vozgravada",
      text: "Desenha muito bem, é super atenciosa e criativa. Sempre presta atenção ao que o cliente pede e consegue transformar tudo em algo incrível e de qualidade. Tem muito talento e ela se destaca muito em cada trabalho, e é sempre um prazer trabalhar com ela. Bom pó compra 😎👍",
    },
    {
      url: "https://www.tiktok.com/@nexusgamer82",
      title: "@nexusgamer82 (Tiktok)",
      text: "Juwu obrigado pelo seu trabalho incrível! agradeço pelo seu esforço, pela sua dedicação e pelo jeito que me atendeu, obrigado mesmo.",
    },
    {
      url: "https://www.instagram.com/guardioes.comics/",
      title: "@guardioes.comics",
      text: "Excelente trabalho, comprometida em entregar o melhor, faz a arte com excelência e empenho, amei o resultado do trabalho dela, recomendo fortemente a conhecer e experimentar o talento dessa jovem artista.",
    },
    {
      url: "https://www.instagram.com/wallacy_mr/",
      title: "@wallacy_mr",
      text: "Olha, já faz mais de 2 anos que sou cliente da Juwu, e só tenho elogios para o trabalho dela. É simplesmente excelente! Ela se dedica aos detalhes com um capricho incrível. Desde o início, quando começamos com pequenas comissões de personagens de histórias em quadrinhos, até agora, em um projeto profissional de HQs, pude acompanhar a evolução dela. E o portfólio de serviços realizados é impressionante: desde artes simples até peças extremamente elaboradas. O compromisso com o cliente é inigualável. Sou muito grato pelos serviços prestados pela Juwu.",
    },
    {
      url: "https://www.instagram.com/oanhanguera/",
      title: "@oanhanguera",
      text: "Sou cliente da Juwu há mais de 2 anos, e não tenho nada além de elogios. O trabalho dela é realmente excepcional. A atenção aos detalhes e o capricho são notáveis. Começamos com ilustrações de personagens de quadrinhos e, ao longo do tempo, acabamos colaborando em um projeto de histórias em quadrinhos profissional. O portfólio dela é diversificado, abrangendo desde artes simples até criações extremamente elaboradas. A Juwu é comprometida e dedicada, e só posso agradecer pelos serviços prestados.",
    },
  ];

  const items = Testimonials.map((testimonial, index) => {
    let icon;
    if (testimonial.url.includes("tiktok")) {
      icon = faTiktok;
    } else if (testimonial.url.includes("instagram")) {
      icon = faInstagram;
    }

    return (
      <SwiperSlide key={index}>
        <div className="testimonial">{testimonial.text}</div>
        <Link onClick={() => handleLinkClick(testimonial.url)} className="user">
          <FontAwesomeIcon icon={icon} />
          {testimonial.title}
        </Link>
      </SwiperSlide>
    );
  });

  return (
    <div className="container-fluid testimonial-container">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={isInView ? { opacity: 1, y: 0 } : ""}
        transition={{ duration: 0.5 }}
        className="container"
        ref={ref}
      >
        <div className="row">
          <div className="col-10 col-md-9">
            <div className="title">Depoimentos de Clientes</div>
          </div>
          <div className="col-12">
            <Swiper modules={[Autoplay]} {...settings}>
              {items}
            </Swiper>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Testimonials;
