import { motion } from "framer-motion";

function About() {
  return (
    <div className="container-fluid about">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="title title-p"
            >
              Sou Juwu
            </motion.div>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="text"
            >
              Olá, meu nome é Juliana, e sou uma ilustradora digital de Manaus,
              Amazonas. Minha jornada artística começou quando eu tinha apenas
              14 anos, usando apenas meu celular, mas com muita dedicação e
              esforço. Minha arte é incrivelmente variada, abrangendo estilos
              que vão desde cartoon e anime até semi-realismo e muito mais.
              Busco constantemente ampliar meus horizontes, explorando uma ampla
              gama de estilos e técnicas. Sou uma pessoa extremamente dedicada,
              e minha paixão pelo desenho me impulsiona a nunca parar de
              aprender e crescer artisticamente.
            </motion.div>
          </div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.8 }}
            className="col-12 col-md-5"
          >
            <img src="/img/about.webp" alt="" className="image-100-p" />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default About;
