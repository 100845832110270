import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import useCustomInView from "../tools/useCustomInView";

function Content() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const modalRef = useRef();

  const [tableRef1, tableSpring1] = useCustomInView('bottom-top', 0);
  const [tableRef2, tableSpring2] = useCustomInView('bottom-top', 200);
  const [tableRef3, tableSpring3] = useCustomInView('bottom-top', 400);
  const [tableRef4, tableSpring4] = useCustomInView('bottom-top', 600);

  const handleButtonClick = (event, buttonId) => {
    const contentElement = document.getElementById(buttonId);
    const buttonElement = event.currentTarget;
    const parentElement = buttonElement.closest(".col-12");
    const title = parentElement.querySelector(".title").innerHTML;
    const content = contentElement.innerHTML;
    
    if (isModalVisible) {
      // Se o modal já está visível, comece a fechar antes de abrir o novo
      setIsClosing(true);
      // Após a animação de fechamento, abra o modal novamente
      setTimeout(() => {
        setModalContent(`<div class="title">${title}</div>${content}`);
        setIsClosing(false);
        setIsModalVisible(true);
      }, 350); // Tempo igual à duração da animação de fechamento
    } else {
      // Se o modal não está visível, apenas abra
      setModalContent(`<div class="title">${title}</div>${content}`);
      setIsModalVisible(true);
    }
  };

  const modalSpring = useSpring({
    opacity: isModalVisible ? 1 : 0,
    config: { duration: 350 },
    onRest: () => {
      if (!isModalVisible && isClosing) {
        setIsClosing(false);
      }
    },
  });

  const unfadeSpring = useSpring({
    opacity: isClosing ? 0 : 1,
    config: { duration: 350 },
    onRest: () => {
      if (isClosing) {
        setIsModalVisible(false);
      }
    },
  });

  const handleCloseModal = () => {
    setIsClosing(true);
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("touchstart", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [isModalVisible]);

  return (
    <div className="container-fluid conteudo-container">
      <div className="container">
        <div className="row">
          <animated.div
            ref={tableRef1}
            style={tableSpring1}
            className="col-12 col-sm-12"
          >
            <div className="top-box">
              <div className="title">Comissão de Arte Livre</div>
              <div className="text">
                Estou oferecendo serviços de desenhos personalizados onde o
                cliente tem total liberdade para escolher ângulos, detalhes
                adicionais, poses específicas e cenários. Esta opção é perfeita
                para quem procura um desenho mais complexo e criativo, em
                diversos estilos de arte que domino, como HQ, Anime, Mangá,
                Cartoon, entre outros.
              </div>
            </div>
            <div
              className="button"
              onClick={(event) => handleButtonClick(event, "arte_livre")}
            >
              + MAIS INFORMAÇÕES +
            </div>
          </animated.div>
          <animated.div
            ref={tableRef2}
            style={tableSpring2}
            className="col-12 col-sm-4"
          >
            <div className="top-box">
              <div className="title">
                Comissões de Personagens de Corpo Inteiro
              </div>
              <div className="text">
                Estou oferecendo serviços de comissão de desenhos de personagens
                de corpo inteiro em vários estilos que domino, como HQ, anime,
                Cartoon e semi realista etc.
              </div>
            </div>

            <div
              className="button"
              onClick={(event) => handleButtonClick(event, "corpo_inteiro")}
            >
              + MAIS INFORMAÇÕES +
            </div>
          </animated.div>
          <animated.div
            ref={tableRef3}
            style={tableSpring3}
            className="col-12 col-sm-4"
          >
            <div className="top-bpx">
              <div className="title">Comissões de Arte Simples</div>
              <div className="text">
                Opções disponíveis para os estilos: Chibi, Cartoon simples,
                Caricatura, Doodle, HQ, Mangá e Anime.
              </div>
            </div>
            <div
              className="button"
              onClick={(event) => handleButtonClick(event, "arte_simples")}
            >
              + MAIS INFORMAÇÕES +
            </div>
          </animated.div>
          <animated.div
            ref={tableRef4}
            style={tableSpring4}
            className="col-12 col-sm-4"
          >
            <div className="top-box">
              <div className="title">Logotipo/Logomarca</div>
              <div className="text">
                Para sua marca e seu negócio, imagine e encomende a sua agora!
              </div>
            </div>
            <div
              className="button"
              onClick={(event) =>
                handleButtonClick(event, "logotipos_logomarcas")
              }
            >
              + MAIS INFORMAÇÕES +
            </div>
          </animated.div>
        </div>
        <div className="row d-none">
          <div className="col-12 col-sm-4" id="arte_livre">
            <ul>
              <div className="title">Rascunho:</div>
              <li>Busto: R$ 3</li>
              <li>Meio Corpo: R$ 6</li>
              <li>Corpo Inteiro: R$ 10</li>
              <div className="title">LineArt:</div>
              <li>Busto: R$ 10</li>
              <li>Meio Corpo: R$ 15</li>
              <li>Corpo Inteiro: R$ 25</li>
              <div className="title">Pintura Básica:</div>
              <li>Busto: R$ 6</li>
              <li>Meio Corpo: R$ 10</li>
              <li>Corpo Inteiro: R$ 15</li>
              <div className="title">Pintura Caprichada:</div>
              <li>Busto: R$ 30</li>
              <li>Meio Corpo: R$ 40</li>
              <li>Corpo Inteiro: R$ 80</li>
              <div className="title">Adicionais:</div>
              <li>Cenário adicional: R$ 5</li>
            </ul>
          </div>
          <div className="col-12 col-sm-4" id="corpo_inteiro">
            <ul>
              <div className="title">Corpo Inteiro:</div>
              <li>Pintura Normal (luz e sombra): R$ 25</li>
              <li>Pintura Caprichada: R$ 35</li>
            </ul>
            <div className="box">
              <div>OPÇÕES ADICIONAIS (PARA AMBAS AS OPÇÕES DE PREÇO):</div>
              <div>
                <div className="title">CENÁRIO:</div>
                <div className="option">
                  Simples (incluso): sem custo adicional
                </div>
                <div className="option">Mais Avançado: R$ 5</div>
              </div>
              <div>
                <div className="title">TAMANHOS RECOMENDADOS:</div>
                <div className="option">Vertical: 862x1280</div>
                <div className="option">Horizontal: 3091x2183</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4" id="arte_simples">
            <div className="box">
              <div>
                <div className="title">Arte Simples (Pintura Base): </div>
                <div className="option">Desenho Simples (colorido): R$ 5</div>
                <div className="option">
                  Desenho Simples (preto e branco): R$3
                </div>
                <div className="option">
                  Arte Avançada (Bem detalhada): R$ 15
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <div className="title">
                  A arte será entregue em formato 1280×1280, perfeito para fotos
                  de perfil. (Peito para cima).
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4" id="logotipos_logomarcas">
            <div className="box">
              <div>
                <div className="option">Logo simples (apenas fonte): R$ 20</div>
                <div className="option">
                  Logo com cores e designs simples: R$ 30
                </div>
                <div className="option">
                  Logo Avançada (bem detalhada): R$ 40
                </div>
              </div>
            </div>
            <div className="box">
              <div>
                <div className="option">
                  Sua logo em diferentes versões para usar em vários formatos
                  (adicional): R$ 10
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <animated.div
          className="modal-conteudo"
          style={isClosing ? unfadeSpring : modalSpring}
          ref={modalRef}
        >
          <div className="close-modal" onClick={handleCloseModal}>
            <div className="bar-1"></div>
            <div className="bar-2"></div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </animated.div>
      )}
    </div>
  );
}

export default Content;
