import { animated } from "@react-spring/web";
import { Link } from "react-router-dom";

import useCustomInView from "../components/tools/useCustomInView";

function Masterpiece() {
  const handleLinkClick = (link) => {
    window.open(link, "_blank");
  };

  const [title, titleStyle] = useCustomInView('bottom-top', 0, true)
  const [text, textStyle] = useCustomInView('bottom-top', 300, true)
  const [btn1, btn1Style] = useCustomInView('bottom-top', 400, true)
  const [btn2, btn2Style] = useCustomInView('bottom-top', 800, true)
  const [hq, hqStyle] = useCustomInView('right-left', 200, true)

  return (
    <div className="container-fluid masterpiece">
      <div className="container">
        <div className="row">
        <div className="col-12 col-md-6">
          <animated.div ref={title} style={titleStyle} className="title">Minha Obra</animated.div>
          <animated.div ref={text} style={textStyle} className="text">
            Se Confesse Mais Uma Vez, é uma obra de romance, comédia, e drama,
            que tenho muito carinho em fazer, eu fiz essa hístoria, no auge de
            minha adolecencia, ainda estou á produzindo! agora uma breve
            sinopse: Akari depois de uma decepção amorosa, decide esquecer seu
            primeiro amor, Adan, mas quando ela menos espera Adan aparece na sua
            vida novamente. Quais são suas intenções dele? Ele poderá
            descongelar o coração de gelo da Akari?
          </animated.div>
          <div className="btn-box">
            <animated.div ref={btn1} style={btn1Style} className="animated-btn">
              <Link
                onClick={() => handleLinkClick("https://tapas.io/series/Se-Confesse-Mais-Uma-Vez-ptbr")}
                className="tapas"
              >
                Ler pelo Tapas
              </Link>
            </animated.div>
            <animated.div ref={btn2} style={btn2Style} className="animated-btn">
              <Link
                onClick={() => handleLinkClick("https://fliptru.com.br/comic/se-confesse-mais-uma-vez/001")}
                className="fliptru"
              >
                Ler pelo Fliptru
              </Link>
            </animated.div>
          </div>
        </div>
        <animated.div ref={hq} style={hqStyle} className="col-12 col-md-4">
          <img src="/img/hq.webp" alt="" className="image-100-p" />
        </animated.div>
        </div>
      </div>
    </div>
  );
}

export default Masterpiece;
