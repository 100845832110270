import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Navigation = () => {
  const handleLinkClick = (link) => {
    if (link.external) {
      window.open(link.url, "_blank");
    }
  };

  const links = [
    { to: "/about", text: "Sobre mim" },
    {
      to: "/discord",
      text: "Entre no meu Discord",
      external: true,
      url: "https://discord.gg/wNZrenRjYH",
    },
    { to: "/masterpiece", text: "Minha Obra" },
    {
      to: "/website",
      text: "Tenha seu próprio website",
      external: true,
      url: "https://pandin.dev",
    },
    {
      to: "/ebook",
      text: "E-book",
      external: true,
      url: "",
    },
    {
      to: "/onomatopeias",
      text: "Onomatopeias",
      external: true,
      url: "https://onomatopeias.com",
    },
  ];

  return (
    <div className="col-12 col-md-7">
      <div className="container">
        {links.map((link, index) => (
          <Link
            className="col-6"
            key={index}
            to={link.to}
            onClick={(e) => {
              if (link.external) {
                e.preventDefault();
                handleLinkClick(link);
              }
            }}
            {...(!link.external ? { "data-nav-link": true } : {})}
          >
            <motion.div
              initial={{ y: 150, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              whileTap={{ y: -10, transition: { duration: 0.3 } }}
              transition={{ duration: index * 0.22 }}
            >
              <div>{link.text}</div>
            </motion.div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navigation;
