import Testimonials from "../components/home/Testimonials";
import Obs from "../components/home/Obs";
import Content from "../components/home/Content";
import Slider from "../components/home/Slider";

function Home() {
  return (
    <div className="home">
      <Slider />
      <Content />
      <Obs />
      <Testimonials />
    </div>
  );
}

export default Home;
